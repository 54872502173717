html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow: overlay;
  font-size: 16px;
  background-color: #000;
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
*::-webkit-scrollbar {
  height: 15px;
  width: 15px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(92, 90, 167, 0.5);
  border-radius: 7px;
  background-clip: padding-box;
  border: 4px solid rgba(0, 0, 0, 0);
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

[class*='MuiPickersClockNumber-clockNumber-'] {
  margin-top: -16px !important;
}

.dhtmlx-error {
  display: none;
}

.gantt_layout_cell.scrollHor_cell {
  z-index: 10;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes space {
  0%   {transform: translate(0, 0);}
  50%  {transform: translate(0, 8px);}
  100% {transform: translate(0, 0);}
}
